.BrainhubCarouselItem {
  left: 0 !important;
}

.BrainhubCarouselItem p {
  width: 100% !important;
}

.BrainhubCarousel__dots .BrainhubCarousel__dot {
  outline: 0;
  width: 10px !important;
  height: 0 !important;
  padding: 10 !important;
  border: none;
  opacity: 0.5;
  font-size: 0;
  cursor: pointer;
  -webkit-appearance: none;
  background-color: #36383f;
}

.BrainhubCarousel__dots .BrainhubCarousel__dot:before {
  content: '';
  display: block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  padding: 0;
  border: none;
  background: #eae8e4;
}
