$divider-color: #eae8e4;

body {
  overflow-x: hidden;
}

button {
  outline: none;
}

.pb--toggle-switch {
  position: relative;
  width: 93px;
  height: 43px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  &-checkbox {
    display: none;
  }
  &-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 1 solid #bbb;
    border-radius: 20px;
    margin: 0;
  }
  &-inner {
    font-size: 17px;
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in-out 0s;
    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 43px;
      padding: 0;
      line-height: 43px;
      color: white;
      font-weight: bold;
      box-sizing: border-box;
    }
    &:before {
      content: 'No';
      font-family: 'Helvetica Neue', sans-serif;

      font-size: 17px;
      line-height: 38px;
      font-weight: 600;
      text-transform: uppercase;
      padding-left: 15px;
      background-color: #ffffff;
      color: #6b1b64;
      border: 3px solid #6b1b64;
      border-radius: 20px;
    }
  }
  &-disabled {
    background-color: #999999;
    cursor: not-allowed;
    &:before {
      border-color: #7d7d7d;
      color: #7d7d7d;
      background-color: transparent;
      cursor: not-allowed;
    }
  }
  &-inner:after {
    content: 'Yes';
    font-family: 'Helvetica Neue', sans-serif;

    font-size: 17px;
    line-height: 43px;
    font-weight: 600;
    text-transform: uppercase;
    padding-right: 15px;
    background-color: #bbb;
    color: #f6f5f3;
    text-align: right;
  }
  &-switch {
    display: block;
    width: 34px;
    margin: 5px 5px 5px 0;
    background: #6b1b64;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 49px;
    border: 0 solid #bbb;
    border-radius: 20px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.13);

    transition: all 0.3s ease-in-out 0s;
  }
  &-checkbox:checked + &-label {
    .pb--toggle-switch-inner {
      margin-left: 0;
    }
    .pb--toggle-switch-switch {
      right: 0px;
    }
  }
}

.DayPicker {
  border: 1px solid $divider-color;
}

.DayPicker:focus {
  outline: 1px solid $divider-color;
}

.DayPicker-wrapper:focus {
  outline: none;
}

.DayPicker-NavButton:focus {
  outline: 2px solid #731e6b;
}

.DayPicker-Day:focus {
  border-radius: 50px;
  outline: none;
  outline-offset: -2px;
}

.DayPicker-Day:hover {
  border-radius: 50px !important;
  outline: 2px solid inverse;
  background-color: #bd94b9 !important;
  outline-offset: -2px;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #bd94b9 !important;
  color: #000000;
  border-radius: 50px !important;
}
.DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #731e6b !important;
}

.DayPicker-Day--outside:hover {
  color: inherit !important;
  background-color: inherit !important;
}

.DayPicker-Day--today {
  border-radius: 50%;
  color: #731e6b !important;
}

.DayPickerInput {
  width: 100%;
  border-radius: 50px !important;
}

.userTable--router {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

body {
  font-family: 'Helvetica Neue', 'Roboto', 'Aria', sans-serif;
  margin: 0;
}

@media (min-width: 300px) and (max-width: 1170px) {
  .DayPicker {
    width: 100%;
  }

  .DayPicker-wrapper {
    width: 100%;
  }

  .DayPicker-Month {
    width: 100%;
  }
  .DayPicker-Day div {
    width: auto !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .DayPicker-Day div div {
    left: auto !important;
    width: 6px !important;
  }
}

@media (max-width: 500px) {
  .DayPicker {
    border-left: none;
    border-right: none;
  }
  .DayPicker-Month {
    margin: 16px 0 0 0;
  }
  .DayPicker-Day {
    padding: 1px;
  }
}

.home-button:hover {
  opacity: 0.9;
}

.rb-tabs-header {
  margin-bottom: 24px;
}

.rb-tabs .rb-tabs-header .rb-tabs-items .rb-tabs-item {
  width: 117px;
  padding: 20px 8px;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  &.active {
    color: #731e6b;
    font-weight: 700;
    font-size: 14px;
  }
}

.rb-tabs .rb-tabs-header .rb-tabs-active-item {
  background: #731e6b;
}

.link-in-footer {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.login-button {
  svg {
    fill: black !important;
  }
  .MuiTypography-h5 {
    color: black !important;
  }
  &:hover {
    svg {
      fill: #731e6b !important;
      transition: 0.3s;
    }
    .MuiTypography-h5 {
      color: #731e6b !important;
      transition: 0.3s;
    }
  }
}

.DayPickerKeyboardShortcuts_show__bottomRight {
  border-right: 33px solid #731e6b;
}
.DayPickerKeyboardShortcuts_show__bottomRight:hover {
  border-right: 33px solid #731e6b;
}
.DayPickerKeyboardShortcuts_show__topRight {
  border-right: 33px solid #731e6b;
}
.DayPickerKeyboardShortcuts_show__topRight:hover {
  border-right: 33px solid #731e6b;
}
.DayPickerKeyboardShortcuts_show__topLeft {
  border-left: 33px solid #731e6b;
}
.DayPickerKeyboardShortcuts_show__topLeft:hover {
  border-left: 33px solid #731e6b;
}

.CalendarDay__default:hover {
  background: #731e6b !important;
  border: 1px double #e4e7e7;
  color: #ffffff;
}

.CalendarDay__selected_span {
  background: #731e6b55;
  border: 1px solid #731e6b55;
  color: #fff;
}
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #731e6b !important;
  border: 1px solid #731e6b !important;
  color: #fff;
}
.CalendarDay__last_in_range {
  border-right: #731e6b !important;
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #731e6b !important;
  border: 1px solid #731e6b !important;
  color: #fff;
}
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: #731e6b55 !important;
  border: 1px solid #731e6b55 !important;
  color: #731e6b !important;
}
.CalendarDay__hovered_span:active {
  background: #731e6b55 !important;
  border: 1px solid #731e6b55 !important;
  color: #731e6b !important;
}

.DateInput {
  width: 100px;
  padding: 0 12px;
  margin: 0 auto;
}

.DateInput_input {
  font-weight: 200;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  width: 100%;
  padding: 9px 0 !important;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  text-align: center;
}

.DateInput_input__focused {
  background: #fff;
  border-bottom: 2px solid #731e6b;
}

.DateRangePickerInput {
  background-color: #fff;
  height: 46px;
  display: flex;
  align-items: center;
}

.DateRangePickerInput__withBorder {
  border: 1px solid rgb(234, 232, 228);
}

.DateRangePickerInput_arrow {
  margin-bottom: 4px;
}
.DateRangePickerInput_arrow_svg {
  vertical-align: middle;
  fill: #000000 !important;
  height: 24px;
  width: 24px;
}

.DateRangePickerInput_clearDates {
  padding: 5px !important;
  margin: 0 10px 0 10px;
}

.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
  background: #eee;
  border-radius: 50%;
}

.DateRangePickerInput_clearDates_svg {
  fill: #000000;
  height: 12px;
  width: 18px;
  vertical-align: middle;
  margin-bottom: 4px;
}

.MuiInputBase-input::-webkit-search-cancel-button {
  display: none;
}

.collapse-title {
  transition: 0.3s;
}

.collapse-box:hover .collapse-title {
  color: #731e6b;
  transition: 0.3s;
}

.collapse-box:hover {
  transition: 0.3s;
  box-shadow: 0px 0px 16px 0px #00000026;
}

.removeDecorators {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
