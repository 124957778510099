.container-carousel {
  max-width: 100% !important;
  position: relative;
}

.BrainhubCarousel {
  max-width: 1440px;
}

.BrainhubCarousel__track li {
  max-width: 100vw !important;
  min-width: auto !important;
}

.BrainhubCarouselItem {
  left: 119px !important;
}

.container-img {
  max-height: 650px;
  width: 100vw;
}

.BrainhubCarousel__arrows {
  position: absolute;
  background-color: white;
  padding: 16px 24px;
  top: 50;
  width: 119px;
  height: 72px;
  border: 1px solid #eae8e4;
}

.BrainhubCarousel__arrows:hover:enabled {
  background-color: white;
}

.BrainhubCarousel__arrows:hover {
  background-color: white;
}

.BrainhubCarousel__arrowLeft {
  left: 0;
  z-index: 1;
  right: 16px;
}

.BrainhubCarousel__arrowRight {
  right: 0;
}

.BrainhubCarousel__arrows span {
  display: none;
}

.BrainhubCarousel__arrows:hover span {
  display: none;
}

.BrainhubCarousel__arrowLeft::after {
  background-image: url(./left-arrow.svg);
  background-size: 30px 40px;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  width: 40px;
  height: 40px;
  content: '';
}

.BrainhubCarousel__arrowRight::after {
  background-image: url(./right-arrow.svg);
  background-size: 30px 40px;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  width: 40px;
  height: 40px;
  content: '';
}

.BrainhubCarousel__container {
  position: relative;
}

.container-dots {
  display: none !important;
}

.BrainhubCarousel__thumbnail {
  padding: 0 !important;
}

.custom-arrows {
  display: none;
}

@media screen and (max-width: 1250px) {
  .container-img {
    max-height: 600px;
    width: 100vw;
  }
}

@media screen and (max-width: 1200px) {
  .container-img {
    max-height: 550px;
    width: 100vw;
  }
}

@media screen and (max-width: 1050px) {
  .container-img {
    max-height: 450px;
  }
}

@media screen and (max-width: 960px) {
  .container-img {
    max-height: 400px;
  }
}

@media screen and (max-width: 860px) {
  .container-img {
    max-height: 300px;
  }
}

@media screen and (max-width: 720px) {
  .BrainhubCarouselItem {
    left: 0px;
  }

  .BrainhubCarousel__container {
    position: static;
  }
  .container-img {
    height: auto;
    width: 100%;
  }

  .BrainhubCarouselItem {
    left: 0 !important;
  }

  .BrainhubCarousel__arrows {
    display: none;
  }

  .BrainhubCarousel__container {
    overflow: visible;
    margin-bottom: 72px;
  }

  .container-dots {
    display: flex !important;
    transform: translateX(auto);
  }

  .BrainhubCarousel__track li {
    max-width: 100vw !important;
    min-width: auto !important;
  }
  .custom-arrow-left {
    display: block;
    background-color: white;
    padding: 16px 24px;
    bottom: 0;
    width: 59px;
    height: 65px;
    border: 1px solid #eae8e4;
    background-image: url(./left-arrow-white.svg);
    background-size: 30px 40px;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
    content: '';
    background-color: #731e6b;
    padding: 8px;
    top: auto;
  }
  .custom-arrow-right {
    display: block;
    background-color: white;
    padding: 16px 24px;
    width: 59px;
    height: 65px;
    border: 1px solid #eae8e4;
    background-image: url(./right-arrow-white.svg);
    background-size: 30px 40px;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
    content: '';
  }

  .custom-arrows {
    background-color: #731e6b;
    width: 71px !important;
    height: 68px !important;
    border: 2px solid #eae8e4 !important;
    cursor: pointer;
    bottom: 3px;
    box-sizing: content-box;
  }
  .custom-arrows:focus {
    outline: none;
  }
}

@media screen and (max-width: 480px) {
  .container-img {
    max-height: 250px;
  }
  .custom-arrows {
    width: 78px !important;
    height: 72px !important;
  }
}

@media screen and (max-width: 370px) {
  .container-img {
    max-height: 200px;
  }
}
