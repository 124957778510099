.spinner-dot {
  display: inline-block;
  position: relative;
}
.spinner-dot div {
  position: absolute;
  top: 0;
  width: 16.25%;
  height: 100%;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: linear;
}
.spinner-dot div:nth-child(1) {
  left: 10%;
  animation: dot1 0.7s infinite;
}
.spinner-dot div:nth-child(2) {
  left: 10%;
  animation: dot2 0.7s infinite;
}
.spinner-dot div:nth-child(3) {
  left: 40%;
  animation: dot2 0.7s infinite;
}
.spinner-dot div:nth-child(4) {
  left: 70%;
  animation: dot3 0.7s infinite;
}
@keyframes dot1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes dot3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes dot2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(184.61%, 0);
  }
}
